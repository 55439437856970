import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section, Strong } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-dark",
	"padding": "80px 0 80px 0",
	"box-shadow": "4px 4px 5px -1px --color-lightD1",
	"border-width": "0 0 .5px 0",
	"border-style": "solid",
	"border-color": "--color-light"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--light",
			"font": "--lead",
			"text-align": "center",
			"text-transform": "uppercase",
			"children": <>
				<Strong>
					Ваша довіра - це найвища честь для нас.{" "}
					<br />
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Нехай Veritas Legal Insights буде вашим маяком ясності та впевненості у всіх юридичних питаннях.
				</Strong>
			</>
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:blank?hello@company.com",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "contact@solarsparket.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:1234567899",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "0661100277"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Voskresenskyi Ave, буд.5, Kyiv, 02125"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;