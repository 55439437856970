import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Strong, Em, Span, LinkBox, Link, Box, Section } from "@quarkly/widgets";
import { FaVolleyballBall } from "react-icons/fa";
const defaultProps = {
	"background": "--color-dark",
	"quarkly-title": "Footer-16",
	"padding": "50px 0 0 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "40%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "#",
			"flex-direction": "row",
			"justify-content": "flex-start"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"color": "--lightD1",
			"size": "32px",
			"margin": "0px 1rem 0px 0px",
			"category": "fa",
			"icon": FaVolleyballBall,
			"display": "none"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline3",
			"children": "Veritas Legal Insights"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-display": "flex",
			"md-grid-gap": "24px",
			"md-align-items": "center",
			"lg-justify-content": "center",
			"text-align": "right",
			"justify-content": "flex-end",
			"align-items": "center"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"display": "grid",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "8px 0",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"lg-border-width": "0px 0px 0px 2px",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/index",
			"margin": "0px 1rem 0px 0",
			"justify-content": "flex-end",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				ГОЛОВНА
			</Strong>
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/about",
			"margin": "0px 1rem 0px 0",
			"justify-content": "flex-end",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				ПРО НАС
			</Strong>
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"href": "/services",
			"justify-content": "flex-end",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				ПОСЛУГИ
			</Strong>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between",
			"padding": "30px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "#2c3339",
			"lg-flex-direction": "column",
			"align-items": "flex-start",
			"display": "none"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-margin": "0px 0px 20px 0px",
			"sm-flex-direction": "column"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 50px 0px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "КОНТАКТИ"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"text-align": "left",
			"margin": "0px 0px 5px 0px",
			"hover-color": "white",
			"href": "+380962603030",
			"children": "+380962603030"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"color": "#c3c8d0",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"text-align": "left",
			"hover-color": "white",
			"href": "mailto:info@kyivdiscovery.com",
			"children": "info@kyivdiscovery.com"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "АДРЕСА"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#c3c8d0",
			"font": "normal 300 16px/1.7 --fontFamily-sans",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "проспект Академіка Глушкова, 1, Kyiv"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-align-self": "stretch",
			"sm-flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 0 0px",
			"md-width": "100%",
			"margin": "0 30px 0 0px",
			"children": <>
				© 2024 Precision Field Football Training.{" "}
			</>
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Icon {...override("icon")} />
				<Text {...override("text")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text1")} />
					<Link {...override("link3")} />
					<Link {...override("link4")} />
				</Box>
				<Box {...override("box6")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Text {...override("text4")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;