import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Em, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "120px 0 120px 0",
	"background": "linear-gradient(180deg,rgba(14, 14, 22, 0.86) 0%,rgba(24, 10, 87, 0.56) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/660e76383f24780021f5a53f/images/1-2.jpg?v=2024-04-04T12:31:02.885Z) center/cover repeat scroll padding-box",
	"sm-padding": "120px 0 124px 0",
	"quarkly-title": "Hero-15"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"text-align": "center",
			"color": "--light",
			"font": "--lead",
			"letter-spacing": "1px",
			"children": "kde jsou vaše právní potřeby splněny s bezkonkurenčním nasazením a odbornými znalostmi."
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 50px 0px",
			"text-align": "center",
			"font": "--headline1",
			"letter-spacing": "1px",
			"color": "--lightD1",
			"padding": "0px 250px 0px 250px",
			"lg-padding": "0px 0 0px 0",
			"md-font": "normal 700 42px/1.2 --fontFamily-sans",
			"sm-font": "normal 700 36px/1.2 --fontFamily-sans",
			"text-transform": "uppercase",
			"children": "Veritas Legal Insights"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "#c8ced8",
			"text-align": "center",
			"padding": "0px 250px 0px 250px",
			"font": "--headline3",
			"lg-padding": "0px 0 0px 0",
			"children": <Em>
				Integrita a přehled na každém kroku
			</Em>
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Text {...override("text2")} />
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;